<template>
    <div class="guang1 flex" :class="[type == 1 ? 'wx_bg' : 'app_bg']">
      <div class="le1" v-if="type == 1">
        勤问律师微信小程序 随时随地发布问题
      </div>
      <div class="le1" v-if="type == 2">
        勤问律师APP(安卓版) 随时随地发布问题
      </div>
      <img src="@/assets/img/wx_code.jpg" alt="" v-if="type == 1">
      <img src="@/assets/img/app_code.png" alt="" v-if="type == 2">
    </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
  /*
    1 微信
    2 安卓APP
  */  
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
     
    }
  },

  mounted() {
   
  },

  methods: {
    
  }
}
</script>

<style lang="scss">

   // 广告
   .guang1{
        width: 586px;
        height: 260px;
        padding: 0 40px;
        margin-top: 24px;
        justify-content: space-between;
        box-sizing: border-box;
        .le1{
          width: 309px;
          font-size: 31px;
          font-weight: 600;
          color: #FFFFFF;
          margin-top: 64px;
          line-height: 45px;
        }
        img{
          width: 160px;
          height: 160px;
          margin-top: 50px;
        }
      }

      .wx_bg{
        background:url('../assets/img/bg6.png') no-repeat;
        background-size: 100% 100%;
      }
      .app_bg{
        background:url('../assets/img/bg6.png') no-repeat;
        background-size: 100% 100%;
      }

</style>